import { times, divide } from 'number-precision'

import { LBS_CONVERSION_COEFFICIENT, METRIC_WEIGHTS } from '@constants/metric'
import { MetricWeight } from '@types'

export const distanceMetrics = ['mm', 'cm', 'm']
export const areaMetrics = ['cm²', 'm²']
export const volumeMetrics = ['cm³', 'm³']
export const weightMetrics = ['g', 'kg', 'ton']
export const durationMetrics = ['days', 'weeks', 'months']

export const defaultDistanceMetric = distanceMetrics[1] // cm
export const defaultAreaMetric = areaMetrics[1] // m²
export const defaultVolumeMetric = volumeMetrics[1] // m³
export const defaultWeightMetric = weightMetrics[1] // kg
export const defaultDurationMetric = durationMetrics[0] // days

export const convertDistance = (
  value: number,
  metric: string | 'mm' | 'cm' | 'm', // TODO: Migrate metric unit to string
  newMetric: string | 'mm' | 'cm' | 'm' // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm' && newMetric === 'cm') return times(value, 100)
  if (metric === 'm' && newMetric === 'mm') return times(value, 1000)
  if (metric === 'cm' && newMetric === 'm') return divide(value, 100)
  if (metric === 'cm' && newMetric === 'mm') return times(value, 10)
  if (metric === 'mm' && newMetric === 'cm') return divide(value, 10)
  if (metric === 'mm' && newMetric === 'm') return divide(value, 1000)
  return value
}

export const convertArea = (
  value: number,
  metric: string | 'cm²' | 'm²', // TODO: Migrate metric unit to string
  newMetric: string | 'cm²' | 'm²' // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm²' && newMetric === 'cm²') return times(value, 10000)
  if (metric === 'cm²' && newMetric === 'm²') return divide(value, 10000)
  return value
}

export const convertVolume = (
  value: number,
  metric: string | 'cm³' | 'm³', // TODO: Migrate metric unit to string
  newMetric: string | 'cm³' | 'm³' // TODO: Migrate metric unit to string
): number => {
  if (metric === 'm³' && newMetric === 'cm³') return times(value, 1000000)
  if (metric === 'cm³' && newMetric === 'm³') return divide(value, 1000000)
  return value
}

export const convertWeight = (
  value: number,
  metric: MetricWeight,
  newMetric: MetricWeight
): number => {
  if ((metric === 'g' && newMetric === 'kg') || (metric === 'kg' && newMetric === 'ton'))
    return divide(value, 1000)
  if ((metric === 'kg' && newMetric === 'g') || (metric === 'ton' && newMetric === 'kg'))
    return times(value, 1000)
  if (metric === 'g' && newMetric === 'ton') return divide(value, 1000000)
  if (metric === 'ton' && newMetric === 'g') return times(value, 1000000)

  if (metric === 'g' && newMetric === 'lb') return divide(value, LBS_CONVERSION_COEFFICIENT)
  if (metric === 'kg' && newMetric === 'lb')
    return convertWeight(convertWeight(value, 'kg', 'g'), 'g', 'lb')
  if (metric === 'ton' && newMetric === 'lb')
    return convertWeight(convertWeight(value, 'ton', 'g'), 'g', 'lb')

  if (metric === 'lb' && newMetric === 'g') return times(value, LBS_CONVERSION_COEFFICIENT)
  if (metric === 'lb' && newMetric === 'kg')
    return times(convertWeight(value, 'g', 'kg'), LBS_CONVERSION_COEFFICIENT)
  if (metric === 'lb' && newMetric === 'ton')
    return times(convertWeight(value, 'g', 'ton'), LBS_CONVERSION_COEFFICIENT)

  return value
}

export function isMetricWeight(maybeWeight: unknown): maybeWeight is MetricWeight {
  // @ts-ignore // TODO
  return typeof maybeWeight === 'string' && METRIC_WEIGHTS.includes(maybeWeight as MetricWeight)
}

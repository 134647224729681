// @flow
import { defineMessages } from 'react-intl'

export default (defineMessages({
  checked: {
    id: ' ',
    defaultMessage: ' ',
  },
  followers: {
    id: 'components.BatchItem.followers',
    defaultMessage: 'Followers',
  },
  connection: {
    id: 'components.BatchItem.connection',
    defaultMessage: 'Connection',
  },
  batchNo: {
    id: 'components.BatchItem.batchNo',
    defaultMessage: 'Batch No.',
  },
  currentQuantity: {
    id: 'components.BatchItem.currentQuantity',
    defaultMessage: 'Current Quantity',
  },
  quantity: {
    id: 'components.BatchItem.quantity',
    defaultMessage: 'Initial Quantity',
  },
  differenceQuantity: {
    id: 'components.BatchItem.differenceQuantity',
    defaultMessage: 'Quantity Difference',
  },
  initialQuantity: {
    id: 'components.BatchItem.initialQuantity',
    defaultMessage: 'Initial Quantity',
  },
  producedQuantity: {
    id: 'components.BatchItem.producedQuantity',
    defaultMessage: 'Produced Quantity',
  },
  preShippedQuantity: {
    id: 'components.BatchItem.preShippedQuantity',
    defaultMessage: 'Pre-Shipped Quantity',
  },
  shippedQuantity: {
    id: 'components.BatchItem.shippedQuantity',
    defaultMessage: 'Shipped Quantity',
  },
  postShippedQuantity: {
    id: 'components.BatchItem.postShippedQuantity',
    defaultMessage: 'Post-Shipped Quantity',
  },
  deliveredQuantity: {
    id: 'components.BatchItem.deliveredQuantity',
    defaultMessage: 'Delivered Quantity',
  },
  shortQuantity: {
    id: 'components.BatchItem.shortQuantity',
    defaultMessage: 'QTY',
  },
  inventory: {
    id: 'components.BatchItem.inventory',
    defaultMessage: 'INVENTORY',
  },
  user: {
    id: 'components.BatchItem.user',
    defaultMessage: 'USER',
  },
  memo: {
    id: 'components.BatchItem.memo',
    defaultMessage: 'MEMO',
  },
  product: {
    id: 'components.BatchItem.product',
    defaultMessage: 'PRODUCT',
  },
  productProvider: {
    id: 'components.BatchItem.productProvider',
    defaultMessage: 'END PRODUCT',
  },
  batchItem: {
    id: 'components.BatchItem.batchItem',
    defaultMessage: 'BATCH ITEM',
  },
  orderItem: {
    id: 'components.BatchItem.orderItem',
    defaultMessage: 'ORDER ITEM',
  },
  order: {
    id: 'components.BatchItem.order',
    defaultMessage: 'ORDER',
  },
  shipment: {
    id: 'components.BatchItem.shipment',
    defaultMessage: 'SHIPMENT',
  },
  container: {
    id: 'components.BatchItem.container',
    defaultMessage: 'CONTAINER',
  },
  warehouse: {
    id: 'components.BatchItem.warehouse',
    defaultMessage: 'WAREHOUSE',
  },
  batchGroup: {
    id: 'components.BatchItem.batchGroup',
    defaultMessage: 'BATCH GROUP',
  },
  tags: {
    id: 'components.BatchItem.tags',
    defaultMessage: 'TAGS',
  },
  tagsWithout: {
    id: 'components.BatchItem.tagsWithout',
    defaultMessage: 'TAGS WITHOUT',
  },
  poNo: {
    id: 'components.BatchItem.poNo',
    defaultMessage: 'PO No.',
  },
  packageName: {
    id: 'components.BatchItem.packageName',
    defaultMessage: 'PACKAGE NAME',
  },
  packageCapacity: {
    id: 'components.BatchItem.packageCapacity',
    defaultMessage: 'PACKAGE CAPACITY',
  },
  packageQuantity: {
    id: 'components.BatchItem.packageQuantity',
    defaultMessage: 'PACKAGE QUANTITY',
  },
  autoCalculatePackageQuantity: {
    id: 'components.BatchItem.autoCalculatePackageQuantity',
    defaultMessage: 'AUTO CALCULATE PACKAGE QUANTITY',
  },
  packageGrossWeight: {
    id: 'components.BatchItem.packageGrossWeight',
    defaultMessage: 'PACKAGE GROSS WEIGHT',
  },
  packageVolume: {
    id: 'components.BatchItem.packageVolume',
    defaultMessage: 'PACKAGE GROSS VOLUME',
  },
  packageMaxQuantity: {
    id: 'components.BatchItem.packageMaxQuantity',
    defaultMessage: 'MAX QUANTITY / PACKAGE',
  },
  packageSize: {
    id: 'components.BatchItem.packageSize',
    defaultMessage: 'PACKAGE SIZE (m)',
  },
  packageSizeGrouped: {
    id: 'components.BatchItem.packageSizeGrouped',
    defaultMessage: 'PACKAGE SIZE (L x H x D)',
  },
  productName: {
    id: 'components.BatchItem.productName',
    defaultMessage: 'Product Name',
  },
  productSerial: {
    id: 'components.BatchItem.productSerial',
    defaultMessage: 'Product Serial',
  },
  containerFreeTimeDueDate: {
    id: 'components.BatchItem.containerFreeTimeDueDate',
    defaultMessage: 'Container Due Date',
  },
  containerWarehouseAgreedArrivalDate: {
    id: 'components.BatchItem.containerWarehouseAgreedArrivalDate',
    defaultMessage: 'Container Warehouse Agreed Arrival Date',
  },
  shipmentNo: {
    id: 'components.BatchItem.shipmentNo',
    defaultMessage: 'Shipment No.',
  },
  shipmentLoadPort: {
    id: 'components.BatchItem.shipmentLoadPort',
    defaultMessage: 'Load Port',
  },
  shipmentLoadPortDeparture: {
    id: 'components.BatchItem.shipmentLoadPortDeparture',
    defaultMessage: 'Load Port Departure',
  },
  shipmentDischargePort: {
    id: 'components.BatchItem.shipmentDischargePort',
    defaultMessage: 'Discharge Port',
  },
  shipmentDischargePortArrival: {
    id: 'components.BatchItem.shipmentDischargePortArrival',
    defaultMessage: 'Discharge Port Arrival',
  },
  shipmentTags: {
    id: 'components.BatchItem.shipmentTags',
    defaultMessage: 'Tags',
  },
  shipmentTagsWithout: {
    id: 'components.BatchItem.shipmentTagsWithout',
    defaultMessage: 'Tags Without',
  },
  shipmentStatus: {
    id: 'components.BatchItem.shipmentStatus',
    defaultMessage: 'Status',
  },
  shipmentCargoReadyInitialDate: {
    id: 'components.BatchItem.shipmentCargoReadyInitialDate',
    defaultMessage: 'Cargo Ready Initial Date',
  },
  shipmentCargoReadyResultDate: {
    id: 'components.BatchItem.shipmentCargoReadyResultDate',
    defaultMessage: 'Cargo Ready Result Date',
  },
  shipmentCargoReadyCurrentDate: {
    id: 'components.BatchItem.shipmentCargoReadyCurrentDate',
    defaultMessage: 'Cargo Ready Current Date',
  },
  shipmentLoadPortDepartureInitialDate: {
    id: 'components.BatchItem.shipmentLoadPortDepartureInitialDate',
    defaultMessage: 'Load Port Departure Initial Date',
  },
  shipmentLoadPortDepartureResultDate: {
    id: 'components.BatchItem.shipmentLoadPortDepartureResultDate',
    defaultMessage: 'Load Port Departure Result Date',
  },
  shipmentLoadPortDepartureCurrentDate: {
    id: 'components.BatchItem.shipmentLoadPortDepartureCurrentDate',
    defaultMessage: 'Load Port Departure Current Date',
  },
  shipmentFirstTransitPortArrivalInitialDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortArrivalInitialDate',
    defaultMessage: 'First Transit Arrival Initial Date',
  },
  shipmentFirstTransitPortArrivalResultDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortArrivalResultDate',
    defaultMessage: 'First Transit Arrival Result Date',
  },
  shipmentFirstTransitPortArrivalCurrentDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortArrivalCurrentDate',
    defaultMessage: 'First Transit Arrival Current Date',
  },
  shipmentFirstTransitPortDepartureInitialDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortDepartureInitialDate',
    defaultMessage: 'First Transit Departure Initial Date',
  },
  shipmentFirstTransitPortDepartureResultDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortDepartureResultDate',
    defaultMessage: 'First Transit Departure Result Date',
  },
  shipmentFirstTransitPortDepartureCurrentDate: {
    id: 'components.BatchItem.shipmentFirstTransitPortDepartureCurrentDate',
    defaultMessage: 'First Transit Departure Current Date',
  },
  shipmentSecondTransitPortArrivalInitialDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortArrivalInitialDate',
    defaultMessage: 'Second Transit Arrival Initial Date',
  },
  shipmentSecondTransitPortArrivalResultDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortArrivalResultDate',
    defaultMessage: 'Second Transit Arrival Result Date',
  },
  shipmentSecondTransitPortArrivalCurrentDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortArrivalCurrentDate',
    defaultMessage: 'Second Transit Arrival Current Date',
  },
  shipmentSecondTransitPortDepartureInitialDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortDepartureInitialDate',
    defaultMessage: 'Second Transit Departure Initial Date',
  },
  shipmentSecondTransitPortDepartureResultDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortDepartureResultDate',
    defaultMessage: 'Second Transit Departure Result Date',
  },
  shipmentSecondTransitPortDepartureCurrentDate: {
    id: 'components.BatchItem.shipmentSecondTransitPortDepartureCurrentDate',
    defaultMessage: 'Second Transit Departure Current Date',
  },
  shipmentDischargePortArrivalInitialDate: {
    id: 'components.BatchItem.shipmentDischargePortArrivalInitialDate',
    defaultMessage: 'Discharge Port Arrival Initial Date',
  },
  shipmentDischargePortArrivalResultDate: {
    id: 'components.BatchItem.shipmentDischargePortArrivalResultDate',
    defaultMessage: 'Discharge Port Arrival Result Date',
  },
  shipmentDischargePortArrivalCurrentDate: {
    id: 'components.BatchItem.shipmentDischargePortArrivalCurrentDate',
    defaultMessage: 'Discharge Port Arrival Current Date',
  },
  shipmentCustomClearanceInitialDate: {
    id: 'components.BatchItem.shipmentCustomClearanceInitialDate',
    defaultMessage: 'Custom Clearance Initial Date',
  },
  shipmentCustomClearanceResultDate: {
    id: 'components.BatchItem.shipmentCustomClearanceResultDate',
    defaultMessage: 'Custom Clearance Result Date',
  },
  shipmentCustomClearanceCurrentDate: {
    id: 'components.BatchItem.shipmentCustomClearanceCurrentDate',
    defaultMessage: 'Custom Clearance Current Date',
  },
  shipmentWarehouseArrivalInitialDate: {
    id: 'components.BatchItem.shipmentWarehouseArrivalInitialDate',
    defaultMessage: 'Warehouse Arrival Initial Date',
  },
  shipmentWarehouseArrivalResultDate: {
    id: 'components.BatchItem.shipmentWarehouseArrivalResultDate',
    defaultMessage: 'Warehouse Arrival Result Date',
  },
  shipmentWarehouseArrivalCurrentDate: {
    id: 'components.BatchItem.shipmentWarehouseArrivalCurrentDate',
    defaultMessage: 'Warehouse Arrival Current Date',
  },
  shipmentDeliveryReadyInitialDate: {
    id: 'components.BatchItem.shipmentDeliveryReadyInitialDate',
    defaultMessage: 'Delivery Ready Initial Date',
  },
  shipmentDeliveryReadyResultDate: {
    id: 'components.BatchItem.shipmentDeliveryReadyResultDate',
    defaultMessage: 'Delivery Ready Result Date',
  },
  shipmentDeliveryReadyCurrentDate: {
    id: 'components.BatchItem.shipmentDeliveryReadyCurrentDate',
    defaultMessage: 'Delivery Ready Current Date',
  },
  deliveredAt: {
    id: 'components.BatchItem.deliveredAt',
    defaultMessage: 'Delivery Date',
  },
  deliveredAtDifference: {
    id: 'components.BatchItem.deliveredAtDifference',
    defaultMessage: 'Delivery Date Difference',
  },
  desiredAt: {
    id: 'components.BatchItem.desiredAt',
    defaultMessage: 'Desired Date',
  },
  desiredAtDifference: {
    id: 'components.BatchItem.desiredAtDifference',
    defaultMessage: 'Desired Date Difference',
  },
  shortDeliveryAt: {
    id: 'components.BatchItem.shortDeliveryAt',
    defaultMessage: 'DELIVERY',
  },
  expiredAt: {
    id: 'components.BatchItem.expiredAt',
    defaultMessage: 'Expiry Date',
  },
  producedAt: {
    id: 'components.BatchItem.producedAt',
    defaultMessage: 'Production Date',
  },
  updatedAt: {
    id: 'components.BatchItem.updatedAt',
    defaultMessage: 'Last Modified',
  },
  sort: {
    id: 'components.BatchItem.sort',
    defaultMessage: 'Date Added',
  },
  createdAt: {
    id: 'components.BatchItem.createdAt',
    defaultMessage: 'Date Created',
  },
  adjustmentType: {
    id: 'components.BatchItem.adjustmentType',
    defaultMessage: 'CATEGORY',
  },
  importer: {
    id: 'components.BatchItem.importer',
    defaultMessage: 'IMPORTER',
  },
  exporter: {
    id: 'components.BatchItem.exporter',
    defaultMessage: 'EXPORTER',
  },
  supplier: {
    id: 'components.BatchItem.supplier',
    defaultMessage: 'SUPPLIER',
  },
  status: {
    id: 'components.BatchItem.status',
    defaultMessage: 'STATUS',
  },
  // sections
  sectionBatch: {
    id: 'components.BatchItem.sectionBatch',
    defaultMessage: 'BATCH',
  },
  sectionLinks: {
    id: 'components.BatchItem.sectionLinks',
    defaultMessage: 'LINKS',
  },
  sectionDates: {
    id: 'components.BatchItem.sectionDates',
    defaultMessage: 'DATES',
  },
  sectionPackage: {
    id: 'components.BatchItem.sectionPackage',
    defaultMessage: 'PACKAGING',
  },
  // tooltips
  tooltipDetails: {
    id: 'components.BatchItem.tooltip.details',
    defaultMessage: 'View Batch Details',
  },
  tooltipJumpProduct: {
    id: 'components.BatchItem.tooltip.jumpProduct',
    defaultMessage: 'View Product Details',
  },
  tooltipJumpOrder: {
    id: 'components.BatchItem.tooltip.jumpOrder',
    defaultMessage: 'View Order Details',
  },
  tooltipJumpBatchGroup: {
    id: 'components.BatchItem.tooltip.jumpBatchGroup',
    defaultMessage: 'View Batch Group Details',
  },
  tooltipJumpShipment: {
    id: 'components.BatchItem.tooltip.jumpShipment',
    defaultMessage: 'View Shipment Details',
  },
  tooltipProduct: {
    id: 'components.BatchItem.tooltip.product',
    defaultMessage: '[Product] {product}',
  },
  tooltipSerial: {
    id: 'components.BatchItem.tooltip.serial',
    defaultMessage: '[Serial] {serial}',
  },
  tooltipNo: {
    id: 'components.BatchItem.tooltip.no',
    defaultMessage: '[Batch ID] {no}',
  },
  tooltipPO: {
    id: 'components.BatchItem.tooltip.PO',
    defaultMessage: '[PO] {PO}',
  },
  tooltipBatchGroupNo: {
    id: 'components.BatchItem.tooltip.batchGroupNo',
    defaultMessage: '[Batch Group Serial] {no}',
  },
  tooltipShipmentNo: {
    id: 'components.BatchItem.tooltip.shipmentNo',
    defaultMessage: '[Shipment ID] {no}',
  },
  tooltipQuantity: {
    id: 'components.BatchItem.tooltip.quantity',
    defaultMessage: '[Quantity] {quantity}',
  },
  tooltipDelivery: {
    id: 'components.BatchItem.tooltip.delivery',
    defaultMessage: '[Delivery Date] {delivery}',
  },
  tooltipExporter: {
    id: 'components.BatchItem.tooltip.exporter',
    defaultMessage: '[Exporter] {exporter}',
  },
  tooltipNotSelectable: {
    id: 'components.BatchItem.tooltip.notSelectable',
    defaultMessage: 'This batch is already in a batch group',
  },
  tooltipExporterError: {
    id: 'components.BatchItem.tooltip.exporterError',
    defaultMessage: 'This batch has a different exporter from the others',
  },
  // validations
  required: {
    id: 'components.BatchItem.validation.required',
    defaultMessage: 'Required',
  },
  duplicateBatchItem: {
    id: 'components.BatchItem.validation.duplicateBatchItem',
    defaultMessage: 'Duplicate batch item',
  },
  // actions
  addAdjustment: {
    id: 'components.BatchItem.addAdjustment',
    defaultMessage: 'ADD ADJUSTMENT',
  },
  save: {
    id: 'components.BatchItem.save',
    defaultMessage: 'SAVE',
  },
  cancel: {
    id: 'components.BatchItem.cancel',
    defaultMessage: 'CANCEL',
  },
  selectNoneRequest: {
    id: 'components.BatchItem.selectNoneRequest',
    defaultMessage: 'Select None.',
  },
  // other
  datePlaceholder: {
    id: 'components.BatchItem.datePlaceholder',
    defaultMessage: 'MM/DD/YYYY',
  },
  noShipmentID: {
    id: 'components.BatchItem.noShipmentID',
    defaultMessage: 'No Shipment ID',
  },
  hasShipment: {
    id: 'components.BatchItem.hasShipment',
    defaultMessage: 'Has Shipment',
  },
  totalPrice: {
    id: 'components.BatchItem.totalPrice',
    defaultMessage: 'TOTAL PRICE',
  },
  totalVolume: {
    id: 'components.BatchItem.totalVolume',
    defaultMessage: 'TOTAL VOLUME',
  },
  active: {
    id: 'components.BatchItem.active',
    defaultMessage: 'ACTIVE',
  },
  completed: {
    id: 'components.BatchItem.completed',
    defaultMessage: 'COMPLETED',
  },
  logs: {
    id: 'components.BatchItem.logs',
    defaultMessage: 'Logs',
  },
  mask: {
    id: 'components.BatchItem.mask',
    defaultMessage: 'Custom fields template',
  },
}): any)

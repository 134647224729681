// @flow strict
import * as React from 'react'
import { cx } from 'react-emotion'

import { LabelWrapperStyle } from './style'

type Props = {
  required?: boolean,
  align?: 'left' | 'right' | 'center',
  height?: string,
  width?: string,
  className?: string,
  color?: string,
  children?: React.Node,
}

const Label = ({
  required = false,
  align = 'left',
  height = '20px',
  width = '100%',
  children,
  className = '',
  color = 'GRAY_DARK',
}: Props): React.Element<'div'> => (
  <div className={cx(LabelWrapperStyle({ align, height, width, color }), className)}>
    {children}
    {required && ' *'}
  </div>
)

export default Label

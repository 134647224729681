// @flow
import * as React from 'react'
import { useIntl } from 'react-intl'

import type { File } from '@graphql/server/flow'

import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import FormDialog from 'components/Dialog/FormDialog'
import { DocumentsUpload } from 'components/Form'
import { formatFilesToArray } from 'utils/file'

import { ParentDialogStyle } from '../../../../style'

type Props = {
  entity: 'Order' | 'OrderItem' | 'Shipment' | 'ProductProvider',
  files: File | File[],
  isDialogOpen: boolean,
  isLoading?: boolean,
  onCancel: Function,
  onRequestClose: Function,
  onSave: Function,
}

const ParentDocumentTypeDialog = ({
  entity,
  files,
  isDialogOpen,
  isLoading = false,
  onCancel,
  onRequestClose,
  onSave,
}: Props): React.Node => {
  const intl = useIntl()
  const [dialogFiles, setDialogFiles] = React.useState(formatFilesToArray(files))

  React.useEffect(() => {
    const [firstType] = getFileTypesByEntity(entity, intl)

    setDialogFiles((_files) => {
      return _files.map((_file) => ({
        ..._file,
        type: firstType.value,
      }))
    })
  }, [entity, intl])

  return (
    <FormDialog
      isOpen={isDialogOpen}
      width="880px"
      onRequestClose={onRequestClose}
      onCancel={onCancel}
      isLoading={isLoading}
      noPadding
      className={ParentDialogStyle}
      onSave={() => onSave(dialogFiles)}
    >
      <DocumentsUpload
        files={dialogFiles}
        isInDialog
        entity={entity}
        onSave={(updatedFiles) => setDialogFiles(updatedFiles)}
        canDownload
        canChangeType
      />
    </FormDialog>
  )
}

export default ParentDocumentTypeDialog
